<template>
  <card>
    <template v-slot:searchHeaderTitle>
      <h4 class="card-title">{{ $t('teaGardenPduService.online_edducation_app') }} {{ $t('globalTrans.form')}}</h4>
    </template>
    <template v-slot:searchBody>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
          <b-col sm="12">
            <div class="group-form-card">
              <b-card>
                <b-form-group label-cols-lg="3" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <ValidationProvider name="Trust Type" vid="trust_type">
                          <b-form-group
                            label-cols-sm="2"
                            label-for="trust_type"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.trust_type') }}
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="trust_type"
                            v-model="formData.trust_type"
                            :options="trustTypeList"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-card>
              <!----------------Common start------------->
              <b-card>
                <b-row>
                  <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                    <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                      <b-form-group
                        class="row"
                        label-for="year"
                        slot-scope="{ valid, errors }">
                        <template v-slot:label>
                          {{ $t('globalTrans.year')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                          plain
                          @change="checkClassWiseRole()"
                          v-model="formData.year"
                          :options="yearList"
                          :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                    <ValidationProvider name="Garden" vid="garden_id" rules="required|min_value:1">
                      <b-form-group
                          class="row"
                          label-for="garden_id"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.garden_name')}} <span class="text-danger">*</span>
                          </template>
                          <b-form-select
                            :disabled="isGardenAdmin"
                            plain
                            v-model="formData.garden_id"
                            :options="gardenList"
                            @change="getSchoolInfo()"
                            :state="errors[0] ? false : (valid ? true : null)"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <template v-if="currentLocale === 'en'">
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="School Name" vid="school_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="school_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.education_institution_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            readonly
                            id="school_name"
                            v-model="formData.school_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </template>
                  <template v-if="currentLocale === 'bn'">
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="School Name" vid="school_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="school_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.education_institution_name') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            readonly
                            id="school_name_bn"
                            v-model="formData.school_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </template>
                </b-row>
                <b-row>
                  <template v-if="currentLocale === 'en'">
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Head Master Name (EN)" vid="head_teacher_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="head_teacher_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.head_master_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            readonly
                            id="head_teacher_name_en"
                            v-model="formData.head_teacher_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </template>
                  <template v-if="currentLocale === 'bn'">
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Head Master Name (EN)" vid="head_teacher_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="head_teacher_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.head_master_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            readonly
                            id="head_teacher_name_bn"
                            v-model="formData.head_teacher_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </template>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Mobile" vid="head_tea_mobile_no" rules="required|min:11|max:11">
                        <b-form-group
                          class="row"
                          label-for="head_tea_mobile_no"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.head_mobile_number') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            readonly
                            id="head_tea_mobile_no"
                            type="number"
                            v-model="formData.head_tea_mobile_no"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
              </b-card>
               <!----------------Common End------------->
              <!----------------Primary start------------->
              <template v-if="formData.trust_type === 1">
                <b-card>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Class Name" vid="class_id" :rules="{required: false}">
                        <b-form-group
                            class="row"
                            label-for="class_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('teaGardenPduService.class_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                @change="checkClassWiseRole()"
                                v-model="primaryDetails.class_id"
                                :options="primaryClassList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Class Roll Id" vid="roll_id" :rules="{required: false}">
                        <b-form-group
                            class="row"
                            label-for="roll_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                                {{ $t('teaGardenPduService.roll_no')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                :disabled="loadingRoleCheck ? true : false"
                                plain
                                v-model="primaryDetails.roll_id"
                                :options="primaryRollList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                              <b-form-select-option :value="0">{{ loadingRoleCheck ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Student Name (EN)" vid="student_name_en" :rules="{required:false}">
                        <b-form-group
                          class="row"
                          label-for="student_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.student_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="student_name_en"
                            v-model="primaryDetails.student_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Student Name(En)" vid="student_name_bn" :rules="{required: false}">
                        <b-form-group
                          class="row"
                          label-for="student_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.student_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="student_name_bn"
                            v-model="primaryDetails.student_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Guardian Name (EN)" vid="guardian_name_en" :rules="{ required:false }">
                        <b-form-group
                          class="row"
                          label-for="guardian_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.guardian_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="guardian_name_en"
                            v-model="primaryDetails.guardian_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Guardian Name(EN)" vid="guardian_name_bn" :rules="{required: false}">
                        <b-form-group
                          class="row"
                          label-for="guardian_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.guardian_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="guardian_name_bn"
                            v-model="primaryDetails.guardian_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Has Labour Child" vid="has_labour_child" :rules="{required: false}">
                          <b-form-group
                            label-cols-sm="4"
                            label-for="has_labour_child"
                            slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            <div style="font-weight: bold">
                              {{ $t('teaGardenPduService.is_labour_child') }} <span class="text-danger">*</span>
                            </div>
                          </template>
                          <b-form-radio-group
                            style="margin-top:5px;"
                            id="has_labour_child"
                            v-model="primaryDetails.has_labour_child"
                            :options="LabourChildList"
                          ></b-form-radio-group>
                          <div class="invalid-feedback">
                              {{ errors[0] }}
                          </div>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col>
                      <b-td colspan="4" align="center">
                          <b-button @click="addItem()" type="button" variant="success" class="mr-2 mb-2 btn-sm">
                            + {{ $t('globalTrans.add_more')}}
                          </b-button>
                      </b-td>
                    </b-col>
                  </b-row>
                </b-card>
                  <!-- -----------------Add More Primary Section Start------------------- -->
                    <b-card>
                      <b-row>
                        <div class="table-wrapper table-responsive">
                          <table class="table table-striped table-hover table-bordered">
                            <b-thead>
                              <tr>
                                <b-th class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.class_name') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.roll_no') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.student_name_status') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.guardian_name_status') }}</b-th>
                                <b-th class="text-center">{{ $t('teaGardenPduService.is_labour_child') }}</b-th>
                                <b-th class="text-center">{{ $t('globalTrans.action') }}</b-th>
                              </tr>
                            </b-thead>
                            <b-tbody>
                              <template v-if="formData.details">
                                  <template v-for="(item, index) in formData.details">
                                    <b-tr :key="'p-' + index">
                                      <b-td class="text-center">{{ index+1 }}</b-td>
                                      <b-td class="text-center">{{ getPrimaryClassList(item.class_id) }}</b-td>
                                      <b-td class="text-center">{{ getRollList(item.roll_id) }}</b-td>
                                      <b-td class="text-center">{{ currentLocale === 'en' ? item.student_name_en : item.student_name_bn }}</b-td>
                                      <b-td class="text-center">{{ currentLocale === 'en' ? item.guardian_name_en : item.guardian_name_bn }}</b-td>
                                      <b-td class="text-center">{{ getHasChildList(item.has_labour_child) }}</b-td>
                                      <td class="text-center">
                                        <b-button variant="action-btn delete mr-1" size="sm" :title="$t('globalTrans.delete')" @click="removeItem(index)" class="action-btn edit"><i class="ri-close-fill"></i></b-button>
                                    </td>
                                    </b-tr>
                                  </template>
                              </template>
                              <template v-else>
                                <b-tr>
                                  <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                </b-tr>
                              </template>
                            </b-tbody>
                          </table>
                        </div>
                      </b-row>
                      <b-row>
                        <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                          <ValidationProvider name="Attachement" vid="attachement">
                            <b-form-group
                              slot-scope="{ valid, errors }"
                              label-for="attachement">
                              <template v-slot:label>
                                {{ $t('teaGardenPduService.attachment') }}
                              </template>
                              <b-input-group>
                              <b-form-file
                                id="attachement"
                                name="attachement"
                                v-model="formData.attachement"
                                @change="onChange($event, 'attachement')"
                                :state="errors[0] ? false : (valid ? true : null)"
                              ></b-form-file>
                              <b-input-group-append v-if="formData.attachement && checkBase64(formData.attachement) === false">
                                <a target="_blank" :href="teaGardenServiceBaseUrl + (isImage(formData.attachement) ? 'storage/' : '') + formData.attachement" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                              </b-input-group-append>
                              </b-input-group>
                              <span class="text-danger">{{ errMessage.attachement }}</span>
                              <div class="d-block invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                    </b-card>
                  <!-- -----------------Add More Primary Section End--------------------- -->
              </template>
              <!------------------Primary End------------->
              <!-------------------Secoundary Start------------->
               <template v-if="formData.trust_type === 2">
                <b-card>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Applicant Name (En)" vid="applicant_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="applicant_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.applicant_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="applicant_name_en"
                            v-model="formData.applicant_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Applicant Name(Bn)" vid="applicant_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="applicant_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.applicant_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="applicant_name_bn"
                            v-model="formData.applicant_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Father Name (EN)" vid="father_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="father_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.father_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="father_name_en"
                            v-model="formData.father_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Father Name(En)" vid="father_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="father_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.father_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="father_name_bn"
                            v-model="formData.father_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Mother Name (EN)" vid="mother_name_en" rules="required">
                        <b-form-group
                          class="row"
                          label-for="mother_name_en"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.mother_name_en') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mother_name_en"
                            v-model="formData.mother_name_en"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xl="5" lg="5" md="5" sm="12">
                      <ValidationProvider name="Mother Name (Bn)" vid="mother_name_bn" rules="required">
                        <b-form-group
                          class="row"
                          label-for="mother_name_bn"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('globalTrans.mother_name_bn') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mother_name_bn"
                            v-model="formData.mother_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="5" lg="5" md="5" sm="12" offset="1">
                      <ValidationProvider name="Class" vid="secoundary_class_id" rules="required|min_value:1">
                        <b-form-group
                            class="row"
                            label-for="secoundary_class_id"
                            slot-scope="{ valid, errors }">
                            <template v-slot:label>
                              {{ $t('teaGardenPduService.class_name')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                                plain
                                v-model="formData.secoundary_class_id"
                                :options="secoundaryClassList"
                                :state="errors[0] ? false : (valid ? true : null)"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                     <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Mobile" vid="mobile" rules="required|min:11|max:11">
                        <b-form-group
                          class="row"
                          label-for="mobile"
                          slot-scope="{ valid, errors }">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.parent_mobile') }} <span class="text-danger">*</span>
                          </template>
                          <b-form-input
                            id="mobile"
                            type="number"
                            v-model="formData.mobile_no"
                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-input>
                          <div class="invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xl="10" lg="10" md="10" sm="12" offset="1">
                      <ValidationProvider name="Has Labour Child" vid="has_previos_scholarship">
                        <b-form-group
                          label-cols-sm="9"
                          label-for="has_previos_scholarship"
                          slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                          <div style="font-weight: bold">
                            {{ $t('teaGardenPduService.has_previos_scholarship') }}
                          </div>
                        </template>
                        <b-form-radio-group
                          style="margin-top:5px;"
                          id="has_previos_scholarship"
                          v-model="formData.has_previos_scholarship"
                          :options="scholarshipList"
                        ></b-form-radio-group>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Photo" vid="photo">
                        <b-form-group
                          label-for="photo"
                          slot-scope="{ valid, errors }"
                          >
                          <template v-slot:label>
                            {{ $t('globalTrans.photo') }}
                          </template>
                          <b-input-group>
                            <b-form-file
                              name="photo"
                              accept=".pdf,.jpeg,.jpg"
                              v-model="formData.photo"
                              @change="onChange($event, 'photo')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <b-input-group-append v-if="formData.photo">
                              <a target="_blank" :href="teaGardenServiceBaseUrl+formData.photo" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                            </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.photo }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                      <ValidationProvider name="Previous Year Mark Sheet" vid="previous_year_mark_sheet">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="previous_year_mark_sheet">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.previous_year_mark_sheet') }}
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="previous_year_mark_sheet"
                            name="previous_year_mark_sheet"
                            v-model="formData.previous_year_mark_sheet"
                            @change="onChange($event, 'previousYearMarkSheet')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.previous_year_mark_sheet">
                            <a target="_blank" :href="teaGardenServiceBaseUrl+formData.previous_year_mark_sheet" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.previousYearMarkSheet }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5" offset="1">
                      <ValidationProvider name="Trust Certificate" vid="trust_certificate">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-for="trust_certificate">
                          <template v-slot:label>
                            {{ $t('teaGardenPduService.trust_certificate') }}
                          </template>
                          <b-input-group>
                          <b-form-file
                            id="trust_certificate"
                            name="trust_certificate"
                            v-model="formData.trust_certificate"
                            @change="onChange($event, 'trustCertificate')"
                            :state="errors[0] ? false : (valid ? true : null)"
                          ></b-form-file>
                          <b-input-group-append v-if="formData.trust_certificate">
                            <a target="_blank" :href="teaGardenServiceBaseUrl+formData.trust_certificate" style="padding: 2px 10px;" class="btn btn-primary btn-sm" ><i class="ri-download-2-line"></i></a>
                          </b-input-group-append>
                          </b-input-group>
                          <span class="text-danger">{{ errMessage.trustCertificate }}</span>
                          <div class="d-block invalid-feedback">
                            {{ errors[0] }}
                          </div>
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </b-row>
                </b-card>
              </template>
              <!-------------------Secoundary End------------->
              <b-row class="text-right mb-3">
                <b-col>
                  <b-button type="submit" @click="formData.status = 1" variant="info" class="mr-2 btn-sm">{{ $t('globalTrans.save_draft') }}</b-button>
                  <b-button type="submit" @click="formData.status = 2" variant="success" class="mr-2 btn-sm">{{ $t('configuration.final_save') }}</b-button>
                  <router-link :to="{ path: '/tea-garden-service/pdu/online-education-application-list'}" class="btn btn-danger btn-sm">
                      {{ $t('globalTrans.cancel') }}
                  </router-link>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-form>
       </b-overlay>
    </ValidationObserver>
    </template>
  </card>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { eduTrustScholarshipStore, eduTrustScholarshipUpdate, eduTrustScholarshipShow } from '../../../api/routes'
import teaGardenService from '@/mixins/tea-garden-service'
export default {
  mixins: [teaGardenService],
  name: 'Form',
  props: ['item'],
    components: {
        ValidationProvider,
        ValidationObserver
    },
  data () {
    return {
      valid: null,
      errors: [],
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      errMessage: {
        attachement: '',
        trustCertificate: '',
        previousYearMarkSheet: '',
        photo: ''
      },
      formData: {
        id: '',
        year: 0,
        status: '',
        fiscal_year_id: '',
        app_id: '',
        trust_type: 1,
        applicant_name_en: '',
        applicant_name_bn: '',
        father_name_en: '',
        father_name_bn: '',
        mother_name_en: '',
        mother_name_bn: '',
        garden_id: 0,
        school_name_en: '',
        school_name_bn: '',
        head_teacher_name_en: '',
        secoundary_class_id: 0,
        mobile_no: '',
        has_previos_scholarship: '',
        photo: [],
        previous_year_mark_sheet: [],
        trust_certificate: [],
        attachement: '',
        details: []
      },
      primaryDetails: {
        class_id: 0,
        roll_id: 0,
        student_name_en: '',
        student_name_bn: '',
        guardian_name_en: '',
        guardian_name_bn: '',
        has_labour_child: ''
      },
      primaryRollList: [],
      loading: false,
      isGardenAdmin: false,
      loadingRoleCheck: false
    }
  },
  created () {
    if (this.$route.query.id) {
      const tmp = this.getEducationTrustEditInfo()
      this.formData = tmp
    }
    if (this.isGardenAdminCheckGardenId()) {
      this.isGardenAdmin = true
      this.formData.garden_id = this.isGardenAdminCheckGardenId()
    }
    this.getPrimaryRollList()
  },
  computed: {
    gardenList () {
      return this.$store.state.TeaGardenService.commonObj.masterTeaGardenGenInfoList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    },
    trustTypeList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Primary' : 'প্রাথমিক' },
        { value: 2, text: this.currentLocale === 'en' ? 'Secondary' : 'মাধ্যমিক' }
      ]
    },
    LabourChildList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 2, text: this.currentLocale === 'en' ? 'No' : 'না' }
      ]
    },
    scholarshipList () {
      return [
        { value: 1, text: this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ' },
        { value: 2, text: this.currentLocale === 'en' ? 'No' : 'না' }
      ]
    },
    primaryClassList () {
      const list = [
          { value: 1, text: this.currentLocale === 'en' ? 'Two' : '২য়' },
          { value: 2, text: this.currentLocale === 'en' ? 'Three' : '৩য়' },
          { value: 3, text: this.currentLocale === 'en' ? 'Four' : '৪র্থ' },
          { value: 4, text: this.currentLocale === 'en' ? 'Five' : '৫ম' }
      ]
      return list
    },
    secoundaryClassList () {
      const list = [
        { value: 1, text: this.currentLocale === 'en' ? 'Six' : '৬ষ্ঠ' },
        { value: 2, text: this.currentLocale === 'en' ? 'Seven' : '৭ম' },
        { value: 3, text: this.currentLocale === 'en' ? 'Eight' : '৮ম' }
      ]
      return list
    },
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.$n(item, { useGrouping: false }) }
        } else {
          return { value: item, text: item }
        }
      })
    }
  },
  watch: {
    'formData.garden_id': function (newVal, oldValue) {
      if (newVal && newVal !== 0) {
        this.getSchoolInfo(newVal)
        this.checkClassWiseRole()
      }
    },
    'formData.trust_type': function (newVal, oldValue) {
      if (newVal) {
        this.getSchoolInfo(newVal)
      }
    }
  },
  methods: {
    getSchoolInfo () {
      const schoolInformation = this.$store.state.TeaGardenService.commonObj.masterSchoolInformationList.find(item => parseInt(item.garden_id) === parseInt(this.formData.garden_id) && JSON.parse(item.school_type).includes(this.formData.trust_type))
        if (schoolInformation !== undefined) {
          const schoolType = JSON.parse(schoolInformation.school_type)
          const schoolTypePrimary = schoolType.includes(1)
          const schoolTypeSecoundry = schoolType.includes(2)
          if (this.formData.trust_type === 1 && schoolTypePrimary) {
            this.formData.head_teacher_name_en = schoolInformation.h_master_name_en
            this.formData.head_teacher_name_bn = schoolInformation.h_master_name_bn
            this.formData.head_tea_mobile_no = schoolInformation.h_master_mobile
            this.formData.school_name_en = schoolInformation.text_en
            this.formData.school_name_bn = schoolInformation.text_bn
          } else if (this.formData.trust_type === 2 && schoolTypeSecoundry) {
            this.formData.head_teacher_name_en = schoolInformation.h_master_name_en
            this.formData.head_teacher_name_bn = schoolInformation.h_master_name_bn
            this.formData.head_tea_mobile_no = schoolInformation.h_master_mobile
            this.formData.school_name_en = schoolInformation.text_en
            this.formData.school_name_bn = schoolInformation.text_bn
          } else {
            this.formData.head_teacher_name_en = ''
            this.formData.head_teacher_name_bn = ''
            this.formData.head_tea_mobile_no = ''
            this.formData.school_name_en = ''
            this.formData.school_name_bn = ''
          }
        } else {
            this.formData.head_teacher_name_en = ''
            this.formData.head_teacher_name_bn = ''
            this.formData.head_tea_mobile_no = ''
            this.formData.school_name_en = ''
            this.formData.school_name_bn = ''
        }
    },
    getPrimaryRollList () {
      this.primaryRollList = [
        { value: 1, text: this.currentLocale === 'en' ? 'One' : '১' },
        { value: 2, text: this.currentLocale === 'en' ? 'Two' : '২' },
        { value: 3, text: this.currentLocale === 'en' ? 'Three' : '৩' }
      ]
    },
    getPrimaryClassList (classId) {
      if (classId === 1) {
        return this.currentLocale === 'en' ? 'Two' : '২য়'
      } else if (classId === 2) {
        return this.currentLocale === 'en' ? 'Three' : '৩য়'
      } else if (classId === 3) {
        return this.currentLocale === 'en' ? 'Four' : '৪র্থ'
      } else if (classId === 4) {
        return this.currentLocale === 'en' ? 'Five' : '৫ম'
      }
    },
    getRollList (rollId) {
      if (rollId === 1) {
        return this.currentLocale === 'en' ? 'One' : '১'
      } else if (rollId === 2) {
        return this.currentLocale === 'en' ? 'Two' : '২'
      } else if (rollId === 3) {
        return this.currentLocale === 'en' ? 'Three' : '৩'
      }
    },
    getHasChildList (childId) {
      if (childId === 1) {
        return this.currentLocale === 'en' ? 'Yes' : 'হ্যাঁ'
      } else if (childId === 2) {
        return this.currentLocale === 'en' ? 'No' : 'না'
      }
    },
    async addItem () {
      if (this.primaryDetails.class_id && this.primaryDetails.roll_id && this.primaryDetails.student_name_en && this.primaryDetails.student_name_bn && this.primaryDetails.guardian_name_en &&
          this.primaryDetails.guardian_name_bn && this.primaryDetails.has_labour_child) {
          const objExist = this.formData.details.find(detail => detail.class_id === this.primaryDetails.class_id && detail.roll_id === this.primaryDetails.roll_id)
          if (typeof objExist === 'undefined') {
            this.formData.details.push(this.primaryDetails)
          } else {
            this.$toast.error({
              title: 'Data has already been added',
              color: '#D6E09B'
            })
          }
          this.primaryDetails = {
            class_id: 0,
            roll_id: 0,
            student_name_en: '',
            student_name_bn: '',
            guardian_name_en: '',
            guardian_name_bn: '',
            has_labour_child: ''
          }
          this.$refs.form.reset()
      }
    },
    removeItem (index) {
      this.formData.details.splice(index, 1)
    },
    onChange (e, type) {
      const selectedFile = e.target.files[0]
      if (selectedFile.size > 1024 * 1024) {
        e.preventDefault()
        this.errMessage[type] = this.$t('dealer.max_upload')
      } else {
        this.errMessage[type] = ''
        const reader = new FileReader()
        reader.onload = (event) => {
          this.formData[e.target.name] = event.target.result
        }
        reader.readAsDataURL(selectedFile)
      }
    },
    async getEducationTrustEditInfo () {
      this.loading = true
      const result = await RestApi.getData(teaGardenServiceBaseUrl, `${eduTrustScholarshipShow}/${this.$route.query.id}`)
      if (result.success) {
        this.formData = result.data
      } else {
        this.formData = []
      }
      this.loading = false
    },
    async checkClassWiseRole () {
      this.loadingRoleCheck = true
      const data = Object.assign({}, { year: this.formData.year, garden_id: this.formData.garden_id, trust_type: this.formData.trust_type, class_id: this.primaryDetails.class_id })
      const result = await RestApi.postData(teaGardenServiceBaseUrl, '/pdu/education-trust-scholarship/application/previous-class-wise-roll', data)
      if (result.success) {
        this.getPrimaryRollList()
        this.primaryRollList = this.primaryRollList.filter(item => {
            return !result.data.includes(item.value)
        })
      } else {
        this.getPrimaryRollList()
      }
      this.loadingRoleCheck = false
    },
    async saveUpdate () {
      if (parseInt(this.formData.status) === 2 && parseInt(this.formData.trust_type) === 1 && this.formData.details.length === 0) {
        this.$toast.success({
          title: this.$t('globalTrans.error'),
          message: this.$t('teaGardenPduService.add_stu'),
          color: '#D6E09B'
        })
        return false
      }
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.$route.query.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${eduTrustScholarshipUpdate}/${this.$route.query.id}`, this.formData)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, eduTrustScholarshipStore, this.formData)
      }
      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
      this.$router.push({ path: '/tea-garden-service/pdu/online-education-application-list' })
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    },
    isImage (path) {
      return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(path)
    },
    checkBase64 (string) {
        var result = ''
        result = string.match('data:')
        if (result) {
            return true
        } else {
            return false
        }
    }
  }
}

</script>
<style scoped>
  .p_heading{
    font-weight: bold;
  }
</style>
